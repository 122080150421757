<template>
  <div>
    <Edit
      :item="currentItem"
      :close="closeEdit"
      :onSubmit="onSubmit"
      :sistemasDisponiveis="sistemas"
      :iconesDisponiveis="iconesDisponiveis"
    />
    <div class="filters row" style="margin-top: 0">
      <div class="col-1-3">
        <!--<span class="filters__search">-->
        <!--<input type="text" name="" value="" placeholder="Filtrar por nome">-->
        <!--<fa-icon icon="search"/>-->
        <!--</span>-->
      </div>
      <div class="col-2-3">
        <!--                <div class="pull-right">-->
        <!--                    <button v-on:click="openCreate" class="button button-success button-block">-->
        <!--                        <fa-icon icon="plus" />-->
        <!--                        Criar-->
        <!--                    </button>-->
        <!--                </div>-->
      </div>
    </div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="VITRINES">
        <div class="table-responsive">
          <datatable :columns="table.columns" :data="vitrines" class="table table-hover">
            <template slot-scope="{ row }">
              <tr>
                <td>{{ row.titulo }}</td>

                <td align="center">
                  <div
                    style="width: 15px; height: 15px"
                    v-bind:style="{ 'background-color': row.cor }"
                  ></div>
                </td>
                <td align="center">{{ row.ordem }}</td>
                <td>{{ row.label }}</td>
                <td>{{ row.home | boolean }}</td>
                <td>{{ row.ativo | boolean }}</td>
                <td>
                  <button
                    type="button"
                    class="button button-primary"
                    v-on:click="openEdit(row)"
                  >
                    <fa-icon icon="pencil-alt" />
                  </button>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import clone from "just-clone";
import { request, get, post, put } from "@/helpers/apiRequest";
import Edit from "./Edit";

const initialItem = {
  id: "",
  label: "",
  titulo: "",
  descricao: "",
  cor: "#000",
  tag_produto: "",
  ativo: true,
  home: true,
  ordem: 0,
  coluna: "",
  valor: 1,
  sistemas: [],
  imagem_desktop: "",
  imagem_mobile: "",
  icone: "",
};

export default {
  name: "vitrines",
  components: {
    Edit,
  },
  props: ["sistemas"],
  data() {
    return {
      vitrines: [],
      loading: false,
      term: "",
      currentItem: {},
      iconesDisponiveis: [],
      table: {
        columns: [
          { label: "Titulo", field: "titulo", sortable: false },
          { label: "Cor", field: "cor", align: "center", sortable: false },
          { label: "Ordem", field: "ordem", align: "center", sortable: false },
          {
            label: "Coluna View",
            field: "label",
            align: "center",
            sortable: false,
          },
          { label: "Exibir na Home", field: "home", sortable: false },
          { label: "Ativo", field: "ativo", sortable: false },
          { label: "", field: "", sortable: false },
        ],
      },
    };
  },
  methods: {
    loadVitrines() {
      this.loading = true;
      get(`/admin/marketing/vitrines`)
        .then((json) => {
          this.vitrines = json;
          this.loading = false;
          this.$emit("vitrines-loaded", json);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show("vitrineEdit");
    },
    closeEdit() {
      this.currentItem = clone(initialItem);
      this.$modal.hide("vitrineEdit");
    },
    onSubmit(item) {
      this.loading = true;
      const request = this.request(item);
      request
        .then(() => {
          this.closeEdit();
          this.loadVitrines();
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
      return request;
    },
    request(item) {
      if (item.id) {
        return put(`/admin/marketing/vitrines/${item.id}`, item);
      }
      return post(`/admin/marketing/vitrines`, item);
    },
    loadIcones() {
      get("/admin/marketing/vitrines/icones")
        .then((json) => {
          this.iconesDisponiveis = json;
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loadVitrines();
    this.loadIcones();
  },
};
</script>
