<style scoped>
.codemirror {
  font-size: 10px;
}

.query-tarefa .codemirror .CodeMirror {
  height: 600px;
}
</style>

<template>
  <modal
    name="vitrineEdit"
    height="auto"
    width="900"
    :lazy="true"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Editar Vitrine">
            <div class="form">
              <div class="alert remover" v-show="!!errorMessage">
                <a href="#" class="close" @click="errorMessage = null"
                  >&times;</a
                >
                Falha ao Salvar alterações:<br />
                <p>{{ errorMessage }}</p>
              </div>
              <div class="xrow">
                <div class="col-2-3">
                  <div class="col-2-3">
                    <div class="form-group">
                      <label>Título</label>
                      <input
                        class="form-control"
                        v-model="item.titulo"
                        maxlength="25"
                      />
                      <div class="message">
                        {{ validation.firstError("item.titulo") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-3">
                    <div class="form-group">
                      <label>Ativo</label>
                      <select class="form-control" v-model="item.ativo">
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-1-1">
                    <div class="form-group">
                      <label>Descrição</label>
                      <input
                        class="form-control"
                        v-model="item.descricao"
                        maxlength="150"
                      />
                      <div class="message">
                        {{ validation.firstError("item.descricao") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-4">
                    <div class="form-group">
                      <label>Tag no Produto</label>
                      <input
                        class="form-control"
                        v-model="item.tag_produto"
                        maxlength="30"
                      />
                      <div class="message">
                        {{ validation.firstError("item.tag_produto") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-4">
                    <div class="form-group">
                      <label>Ordem</label>
                      <input
                        class="form-control"
                        v-model="item.ordem"
                        maxlength="30"
                        type="number"
                      />
                      <div class="message">
                        {{ validation.firstError("item.ordem") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-4">
                    <div class="form-group">
                      <label>Exibir na Home</label>
                      <select class="form-control" v-model="item.home">
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-1-4">
                    <div class="form-group">
                      <label>Coluna View</label>
                      <input
                        class="form-control"
                        :value="item.label"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Cor</label>
                    <ColorPicker :onChange="changeColor" :value="item.cor" />
                  </div>
                </div>
                <div class="col-2-3" style="margin-bottom: 20px">
                  <div class="col-1-3">
                    <div class="form-group">
                      <label>Exibe Título</label>
                      <select class="form-control" v-model="item.exibe_titulo">
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-1-3">
                    <div class="form-group">
                      <label>Exibe Descrição</label>
                      <select
                        class="form-control"
                        v-model="item.exibe_descricao"
                      >
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-1-3">
                  <div class="form-group">
                    <label>Destacar Produtos</label>
                    <select
                      class="form-control"
                      v-model="item.destacar_produto"
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-1-1" style="margin-bottom: 20px">
                <div class="col-1-1">
                  <div class="form-group">
                    <label>Ícone Vitrine</label>
                    <div v-for="icones in iconesDisponiveis">
                      <label class="radio">
                        <input
                          type="radio"
                          v-model="item.icone"
                          :value="icones.descricao"
                          :id="icones.descricao + 'id'"
                          name="inlineRadioOptions"
                        />
                        &ensp; <fa-icon :icon="icones.descricao" /> &emsp;
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1-1" style="margin-bottom: 20px">
                <div class="col-1-1">
                  <div class="form-group">
                    <label>Sistema Exibição</label>
                    <label
                      class="checkbox"
                      v-for="sistema in sistemasDisponiveis"
                    >
                      <input
                        type="checkbox"
                        v-model="item.sistemas"
                        :value="sistema.id"
                        :id="sistema.id + 'id'"
                      />
                      &ensp; {{ sistema.descricao }}
                    </label>
                  </div>
                </div>

                <div class="row-cols-1">
                  <div class="col-1-1" v-show="item.exibe_sql">
                    <div class="form-group">
                      <label>SQL</label>
                      <div class="codemirror">
                        <codemirror
                          id="code"
                          v-model="item.sql"
                          :options="code_options"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="xrow">
                <div class="col-1-2">
                  <image-picker
                    label="Imagem desktop (Recomendado 165 x 330 px)"
                    placeholder="Colar URL ou clique ao lado para upload"
                    v-model="item.imagem_desktop"
                  />
                  <div class="form-group">
                    <div class="message">
                      {{ validation.firstError("item.imagem_desktop") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-2">
                  <image-picker
                    label="Imagem mobile (Recomendado 527 x 60 px)"
                    placeholder="Colar URL ou clique ao lado para upload"
                    v-model="item.imagem_mobile"
                  />
                  <div class="form-group">
                    <div class="message">
                      {{ validation.firstError("item.imagem_mobile") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                v-on:click="handleSubmit()"
              >
                Salvar
              </button>
              <button type="button" class="button" v-on:click="close">
                Fechar
              </button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import ColorPicker from "../../components/colorPicker";
import ImagePicker from "../../components/ImagePicker";
import CloseModal from "../../components/CloseModal";
import { codemirror } from "vue-codemirror";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "vitrinesEdit",
  components: {
    ColorPicker,
    ImagePicker,
    CloseModal,
    codemirror,
  },
  props: [
    "onClose",
    "onSubmit",
    "item",
    "close",
    "sistemasDisponiveis",
    "iconesDisponiveis",
  ],
  data() {
    return {
      errorMessage: null,
      colorPicker: {
        colors: "#f345f3",
      },
    };
  },

  validators: {
    "item.titulo": (value) => Validator.value(value).required(),
    "item.cor": (value) => Validator.value(value).required(),
    "item.tag_produto": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
  },

  computed: {
    code_options() {
      return {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        line: true,
        mode: "text/x-sql",
        autoRefresh: true,
        lineWrapping: true,
        readOnly: false,
        disabled: true,
        theme: "monokai",
        showCursorWhenSelecting: false,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true,
        },
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.onSubmit(this.item).catch((error) => {
            this.errorMessage = error.message;
          });
        }
      });
    },
    changeColor(cor) {
      this.item.cor = cor;
    },
  },
};
</script>
